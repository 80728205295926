













































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
//  types
import { LongTrendDiagnosticType } from "@/types/longTrendDiagnosticType";
//  modules
import LongTrendsClient from "Clients/long-trends-client";
import SnackbarModule from "@/store/clients/Snackbar.module";

const Snackbar = getModule(SnackbarModule, store);

interface PerformanceStatusIconSettings {
  color: string;
  icon: string;
}

@Component({})
export default class PerformanceStatusItem extends Vue {
  @Prop({ required: true }) longTrendDiagnosticType!: LongTrendDiagnosticType;

  get longTrendBenchmarkLevelIconSettings(): { tooltip: string; icon: string } {
    if (this.longTrendDiagnosticType.useManualBenchmarkLevel) {
      return {
        tooltip: `Using manual benchmark level: ${this.longTrendDiagnosticType.manualBenchmarkLevel}%`,
        icon: "mdi-alpha-m-circle",
      };
    } else {
      return {
        tooltip: "Using automatic calculated benchmark level",
        icon: "mdi-alpha-a-circle",
      };
    }
  }

  get performanceStatusIconSettings(): PerformanceStatusIconSettings {
    switch (this.longTrendDiagnosticType.performanceStatus) {
      case "Ok":
        return { color: "success", icon: "mdi-check-circle" };
      case "NotOk":
        return { color: "error", icon: "mdi-alert-circle" };
      case "Observe":
        return { color: "warning", icon: "mdi-alert-circle" };
      case "BenchMarking":
        return { color: "info", icon: "mdi-chart-box" };
      default:
        return { color: "", icon: "mdi-circle" };
    }
  }

  async onUseInVesselStatusChange(useInVesselStatus: boolean): Promise<void> {
    try {
      await LongTrendsClient.update(this.longTrendDiagnosticType);
      Snackbar.showSnackbar({ text: "Successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update" });
    }
  }
}
