













import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { LogVariable } from "@/types/logVariable";
import { VesselHistory, VesselHistoryAdditional, VesselHistories } from "@/types/vesselHistory";
import { LongTrendDiagnosticType } from "@/types/longTrendDiagnosticType";
//  components
import VesselInfoContentLoader from "@/components/VesselInfo/VesselInfoContentLoader.vue";
import VesselInfoContent from "@/components/VesselInfo/VesselInfoContent.vue";
//  modules
import VesselsModule from "@/store/clients/Vessels.module";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import VesselsHistoriesModule from "@/store/clients/VesselsHistories.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import LongTrendsClient from "Clients/long-trends-client";

const Vessels = getModule(VesselsModule, store);
const LogVariables = getModule(LogVariablesModule, store);
const VesselsHistories = getModule(VesselsHistoriesModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    VesselInfoContentLoader,
    VesselInfoContent,
  },
})
export default class VesselInfo extends Vue {
  isTrendPeriodLoading = true;
  isLogVariablesLoading = true;
  isVesselHistoryAdditionalsLoading = true;
  isLatestVesselHistoryLoading = true;
  isVesselHistoriesLoading = true;
  latestVesselHistory!: VesselHistory;
  vesselHistoryAdditionals!: VesselHistoryAdditional[];
  longTrendsDiagnosticType!: LongTrendDiagnosticType[];
  vesselHistories!: VesselHistories;

  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get vesselLogVariables(): LogVariable[] {
    return LogVariables.currentVesselLogVariables;
  }

  get isVesselDataLoading(): boolean {
    const isLoading = this.isLogVariablesLoading || this.isLatestVesselHistoryLoading || this.isVesselHistoryAdditionalsLoading || this.isTrendPeriodLoading || this.isVesselHistoriesLoading;
    return isLoading;
  }

  async fetchLogVariables(): Promise<void> {
    if (!this.vessel?.id) return Promise.reject();
    try {
      await LogVariables.refreshCurrentVesselLogVariables(this.vessel.id);
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to load vessel log variables" });
    }
    this.isLogVariablesLoading = false;
  }

  async fetchLatestVesselHistory(): Promise<void> {
    if (!this.vessel?.id) return Promise.reject();
    try {
      this.latestVesselHistory = await VesselsHistories.getLatestVesselHistory(this.vessel.id);
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to load vessel latest history" });
    }
    this.isLatestVesselHistoryLoading = false;
  }

  async fetchVesselHistories(): Promise<void> {
    if (!this.vessel?.id) return Promise.reject();
    try {
      this.vesselHistories = await VesselsHistories.getVesselHistories({ vessel: this.vessel, timespan: 14 });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to load vessel histories" });
    }
    this.isVesselHistoriesLoading = false;
  }

  async fetchVesselHistoryAdditionals(): Promise<void> {
    if (!this.vessel?.id) return Promise.reject();
    try {
      this.vesselHistoryAdditionals = await VesselsHistories.getVesselHistoryAdditionals(this.latestVesselHistory.id);
      this.vesselHistoryAdditionals = this.vesselHistoryAdditionals.map((vesselHistory: VesselHistoryAdditional) => {
        vesselHistory.logVariable = this.vesselLogVariables.find(logVariable => logVariable.id === vesselHistory.logVariableId);
        return vesselHistory;
      });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to load vessel history additional information" });
    }
    this.isVesselHistoryAdditionalsLoading = false;
  }

  async fetchLongTrends(): Promise<void> {
    if (!this.vessel?.id) return Promise.reject();
    try {
      this.longTrendsDiagnosticType = await LongTrendsClient.findLongTrends(this.vessel.id);
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to load vessel long trends" });
    }
    this.isTrendPeriodLoading = false;
  }

  async created(): Promise<void> {
    await this.fetchLogVariables();
    await this.fetchLatestVesselHistory();
    await this.fetchVesselHistories();
    await this.fetchVesselHistoryAdditionals();
    await this.fetchLongTrends();
  }
}
