
















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
//  utilities
import dateHelper from "Utilities/date-helper";
import _ from "underscore";
import moment from "moment";
//  types
import { VesselHistory, VesselHistoryAdditional, VesselHistories } from "@/types/vesselHistory";
import { LongTrendDiagnosticType } from "@/types/longTrendDiagnosticType";
import { LogVariable } from "@/types/logVariable";
import { ExtendedVessel } from "@/types/Vessel";
//  components
import VesselInfoContentCard from "@/components/VesselInfo/VesselInfoContentCard.vue";
import PerformanceStatusItem from "@/components/VesselInfo/PerformanceStatusItem.vue";
import Map from "@/components/Map.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    VesselInfoContentCard,
    PerformanceStatusItem,
    Map,
  },
})
export default class VesselInfoContent extends Vue {
  @Prop({ required: true }) latestVesselHistory!: VesselHistory;
  @Prop({ required: true }) vesselHistoryAdditionals!: VesselHistoryAdditional[];
  @Prop({ required: true }) longTrendsDiagnosticType!: LongTrendDiagnosticType[];
  @Prop({ required: true }) vesselHistories!: VesselHistories;

  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get diagnosticModuleNotActivated(): boolean {
    if (!this.vessel?.performance) return false;
    return this.vessel.performance.performanceStatus === "DiagnosticsNotActivated";
  }

  get noDiagnosticTypes(): boolean {
    if (!this.vessel?.performance) return false;
    return this.vessel.performance.performanceStatus === "NoDiagnosticTypes";
  }

  get generalInfo(): { description: string; value: string | null }[] {
    if (this.hasNoImports) return [{ description: "IMO Number", value: `IMO${this.vessel?.imoNumber}` }];
    return [
      { description: "IMO Number", value: `IMO${this.vessel?.imoNumber}` },
      { description: "Current voyage", value: this.latestVesselHistory.voyage.voyageName },
      { description: "Departure port", value: this.latestVesselHistory.voyage.departurePort },
      { description: "Arrival port", value: this.latestVesselHistory.voyage.arrivalPort },
      { description: "Last data sent", value: dateHelper.getFormatedDateTimeString(this.vessel?.lastExportDate) },
      { description: "Trend period", value: this.trendPeriod || null },
      { description: "Logging history period", value: this.loggingHistoryPeriod || null },
    ];
  }

  get nauticalInfo(): { description: string; value: string | undefined }[] {
    return [
      { description: "Speed by log", value: this.prettyLogVariable(this.latestVesselHistory.logSpeed, this.latestVesselHistory.logSpeedLogVariable) },
      { description: "Speed by GPS", value: this.prettyLogVariable(this.latestVesselHistory.gpsSpeed, this.latestVesselHistory.gpsSpeedLogVariable) },
      { description: "Latitude", value: this.prettyLongLatValue(this.latestVesselHistory.latitude, "latitude") },
      { description: "Longitude", value: this.prettyLongLatValue(this.latestVesselHistory.longitude, "longitude") },
      { description: "Course", value: this.prettyLogVariable(this.latestVesselHistory.course, this.latestVesselHistory.courseLogVariable) },
      { description: "Draft mean", value: this.prettyLogVariable(this.latestVesselHistory.draftMean, this.latestVesselHistory.draftMeanLogVariable) },
      { description: "Trim", value: this.prettyLogVariable(this.latestVesselHistory.trim, this.latestVesselHistory.trimLogVariable) },
    ];
  }

  get additionalInfo(): { description: string; value: string | undefined }[] {
    return this.vesselHistoryAdditionals.map(vesselHistory => ({
      description: vesselHistory.description,
      value: this.prettyLogVariable(vesselHistory.value, vesselHistory.logVariable),
    }));
  }

  get trendPeriod(): string | undefined {
    if (!this.vessel) return;
    const trendDataMinDate = this.vessel.trendDataMinDate;
    const trendDataMaxDate = this.vessel.trendDataMaxDate;
    if (trendDataMinDate === null || trendDataMaxDate === null) return "Not included";

    return `${dateHelper.getFormatedDateString(trendDataMinDate)} - ${dateHelper.getFormatedDateString(trendDataMaxDate)}`;
  }

  get loggingHistoryPeriod(): string | undefined {
    if (!this.vessel) return;
    const logDataMinDate = this.vessel.logDataMinDate;
    const logDataMaxDate = this.vessel.logDataMaxDate;
    if (!logDataMinDate || !logDataMaxDate) return "Not included";

    return `${dateHelper.getFormatedDateString(logDataMinDate)} - ${dateHelper.getFormatedDateString(logDataMaxDate)}`;
  }

  get hasDiagnostics(): boolean {
    const hasDiagnosticTypeId = this.longTrendsDiagnosticType?.some(longTrend => longTrend.diagnosticTypeId !== null);
    return Boolean(this.vessel?.isDiagnosticActivated) && Boolean(this.longTrendsDiagnosticType.length) && hasDiagnosticTypeId;
  }

  get hasNoImports(): boolean {
    return _.isEmpty(this.latestVesselHistory);
  }

  prettyLogVariable(value: number, variable: LogVariable | undefined): string | undefined {
    if (!this.$root.$options.filters?.prettyLogVariable) return;
    return this.$root.$options.filters?.prettyLogVariable(value, variable);
  }

  prettyLongLatValue(value: number, variable: string): string | undefined {
    if (!this.$root.$options.filters?.prettyLogVariable) return;
    if (variable === "latitude") return this.$root.$options.filters?.prettyLatitude(value);
    else if (variable === "longitude") return this.$root.$options.filters?.prettyLongitude(value);
  }

  get prettyStatusUpdatedTime(): string | undefined {
    const maxStatusUpdatedLongTrend = _.max(this.longTrendsDiagnosticType, function (longTrend) {
      return moment(longTrend.performanceStatusUpdated);
    });
    if (_.isNumber(maxStatusUpdatedLongTrend)) return;
    return dateHelper.getFormatedDateString(maxStatusUpdatedLongTrend["performanceStatusUpdated"]);
  }
}
